import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Image, Section, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				CityCruiser Rentals
			</title>
			<meta name={"description"} content={"У CityCruiser Rentals ми пропонуємо більше, ніж просто прокат велосипедів – ми пропонуємо повний досвід дослідження міста."} />
			<meta property={"og:title"} content={"CityCruiser Rentals"} />
			<meta property={"og:description"} content={"У CityCruiser Rentals ми пропонуємо більше, ніж просто прокат велосипедів – ми пропонуємо повний досвід дослідження міста."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.Header>
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
						Готові досліджувати?
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
						Забронюйте свій міський круїз сьогодні та пориньте в серце міста з комфортом і стилем! З CityCruiser Rentals кожна поїздка — це можливість для нової пригоди. Відчуйте найкраще від міських велосипедних прогулянок разом з нами.
						<br />
						{"\n"}
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Адреса
						</Strong>
						: Maksyma Kryvonosa St, 4,
						<br />
						{" "}Lviv, Lviv Oblast, 79000{"\n"}
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Номер телефону:
						</Strong>
						{" "}0680068455
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/2-4%20%281%29.jpg?v=2024-06-11T09:36:20.667Z"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
					srcSet="https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/2-4%20%281%29.jpg?v=2024-06-11T09%3A36%3A20.667Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/2-4%20%281%29.jpg?v=2024-06-11T09%3A36%3A20.667Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/2-4%20%281%29.jpg?v=2024-06-11T09%3A36%3A20.667Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/2-4%20%281%29.jpg?v=2024-06-11T09%3A36%3A20.667Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/2-4%20%281%29.jpg?v=2024-06-11T09%3A36%3A20.667Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/2-4%20%281%29.jpg?v=2024-06-11T09%3A36%3A20.667Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/2-4%20%281%29.jpg?v=2024-06-11T09%3A36%3A20.667Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link3">
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Maksyma Kryvonosa St, 4,
					<br />
					{" "}Lviv, Lviv Oblast, 79000
				</Em>
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});